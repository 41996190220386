'use client';
import { useState } from 'react';
import { ModalPopup } from '../../app/ModalPopup';

export const MobileMenu = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="lg:hidden">
      <div
        onClick={() => {
          setMenuOpen(true);
        }}
        className="cursor-pointer bg-white/50 rounded-full px-2 py-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-8 h-8 "
        >
          <path
            fillRule="evenodd"
            d="M2 3.75A.75.75 0 012.75 3h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zm0 4.167a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75zm0 4.166a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75zm0 4.167a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <ModalPopup
        isOpen={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
        scrollable={false}
        alignTop
        style={{
          padding: 0,
        }}
      >
        <div className="bg-white p-6 pt-2">{children}</div>
      </ModalPopup>
    </div>
  );
};
