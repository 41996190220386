'use client';
import mixpanel, { Dict, Query } from 'mixpanel-browser';
import { getSession } from 'next-auth/react';
import { useEffect } from 'react';
import { MIXPANEL_TOKEN } from '@/constants';

mixpanel.init(MIXPANEL_TOKEN, {
  // Use your project's URL, adding a slug for all Mixpanel requests
  api_host: process.env.NEXT_PUBLIC_WEBSITE_URL + '/mp',
  track_pageview: true, // this will only handle initial page view event

  // debug in development
  debug: process.env.NODE_ENV === 'development',
});

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (
    name: string,
    props?: Dict,
    options?: {
      transport?: 'sendBeacon' | 'XHR';
      send_immediately?: boolean;
    },
    callback?: (data: any) => void,
  ) => {
    mixpanel.track(name, props, options, callback);
  },
  track_pageview: () => {
    mixpanel.track_pageview();
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict, callback?: (data: any) => void) => {
      mixpanel.people.set(props, callback);
      if (props.organizationId) {
        mixpanel.register({
          organizationId: props.organizationId,
        });
        mixpanel.set_group('organizationId', props.organizationId);
        mixpanel.get_group('organizationId', props.organizationId).set({
          isOrganization: true,
        });
      }
    },
  },

  reset: () => {
    mixpanel.reset();
  },
};

let _initialized = false;

export const useMixpanel = () => {
  useEffect(() => {
    if (_initialized) return;
    _initialized = true;

    getSession().then((session) => {
      if (session) {
        Mixpanel.identify(session.user.id);
        Mixpanel.people.set({
          $email: session.user.email,
          $name: session.user.name || undefined,
          $created: session.user.createdAt
            ? new Date(session.user.createdAt).toISOString()
            : undefined,
          organizationRole: session.role,
          organizationId: session.organization?.id,
        });
      }
    });
  }, []);
};

export const UseMixpanel = () => {
  useMixpanel();
  return null;
};

export const TrackEvent = ({ name, props }: { name: string; props?: Dict }) => {
  useEffect(() => {
    Mixpanel.track(name, props);
  }, [name, props]);
  return null;
};
