'use client';
import Link from 'next/link';
import { useState } from 'react';

export const MenuDropDown = ({ children, title, href = '' }) => {
  const [hover, setHover] = useState(false);

  const css = hover
    ? 'lg:transition ease-out lg:duration-100 transform opacity-100 scale-100 pointer-events-auto block'
    : 'lg:transition ease-in lg:duration-75 transform opacity-0 scale-95 pointer-events-none hidden lg:block ';

  const ButtonEl = (
    <button
      type="button"
      className="inline-flex  justify-center font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-400"
      id="options-menu"
      aria-haspopup="true"
      aria-expanded="true"
    >
      <span>{title}</span>
      {/* <svg
        className="-mr-1 ml-2 h-6 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg> */}
    </button>
  );

  return (
    <div
      className="mouse-hover-wrapper z-50 relative inline-block text-left w-full "
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setHover((h) => !h)}
    >
      <div>
        {href ? (
          <Link href={href} className="inline-flex justify-center">
            {ButtonEl}
          </Link>
        ) : (
          ButtonEl
        )}
      </div>
      <div className="pt-4 -mb-4 relative">
        <div
          className={
            'lg:origin-top-center overflow-hidden lg:absolute right-1/2  lg:translate-x-1/2 lg:w-auto rounded-md lg:shadow-xl bg-white lg:ring-1 lg:ring-black lg:ring-opacity-5 w-full ' +
            css
          }
        >
          {children}
        </div>
        {/* arrow */}
        <div
          className={
            'absolute top-1/2 left-1/2 transform  -translate-x-1/2  ' + css
          }
        >
          <div
            className="rotate-45 rounded-sm border border-solid border-black border-opacity-5"
            style={{
              width: '20px',
              height: '20px',
              marginTop: -2,
              backgroundColor: 'white',
              borderRightWidth: 0,
              borderBottomWidth: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};
