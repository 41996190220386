'use client';
import { useEffect } from 'react';
import Modal from 'react-modal';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '95vh',
    maxWidth: 'calc(100vw - 2rem)',
    borderRadius: '1rem',
    padding: '3rem',
    paddingBottom: 0,
    overflowX: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
};

export const ModalPopup = ({
  isOpen,
  onClose,
  children,
  scrollable = true,
  style = {},
  alignTop = false,
}) => {
  useEffect(() => {
    Modal.setAppElement('#body-root');
  }, []);

  const topTransform = alignTop ? '20px' : '-50%';

  return (
    <>
      <style>
        {`

      @keyframes fadeInUpModal {
        0% {
          opacity: 0;
          transform: translate(-50%, calc(${topTransform} + 20px));
        }
        100% {
          opacity: 1;
          transform: translate(-50%, ${topTransform});
        }
      }

        #animated-modal {
          opacity: 0;
          animation-name: fadeInUpModal;
          animation-duration: 250ms;
          animation-fill-mode: forwards;
          animation-delay: 100ms;
        }

        .ReactModal__Overlay {
          opacity: 0;
          animation-name: fadeIn;
          animation-duration: 250ms;
          animation-fill-mode: forwards;
          z-index: 1000;
        }
      `}
      </style>
      <Modal
        id="animated-modal"
        isOpen={!!isOpen}
        onRequestClose={onClose}
        style={{
          ...modalStyle,
          content: {
            ...modalStyle.content,
            overflowY: scrollable ? 'auto' : 'hidden',
            inset: alignTop ? '0 0 auto 50%' : '50% auto auto 50%',
            ...style,
          },
        }}
      >
        {children}

        <div
          className={
            'absolute ' + (alignTop ? ' top-2 right-2 ' : ' top-6 right-6 ')
          }
        >
          <CloseButton onClick={onClose} />
        </div>
      </Modal>
    </>
  );
};

export const BottomActionsView = ({
  children,
  absolute = false,
  className = '',
}) => {
  return (
    <div
      className={
        (absolute
          ? 'flex justify-between py-6 sticky bg-white bottom-0 left-0 right-0 pseudo-white-filler '
          : 'flex justify-between mt-12  py-6 relative ') + className
      }
    >
      <div
        className="border-t border-slate-300 absolute top-0 w-screen"
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      ></div>
      {children}
    </div>
  );
};

const CloseButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="text-slate-500 rounded-full h-10 w-10 flex justify-center items-center hover:bg-slate-50"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
);
